// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { GET_API, POST_API } from "../../../store/actions.type";
import store from '@/store'
import moment from "moment";
import Bus from "../../../event-bus";
import router from '../../../router'

export default function userCalendar() {
  // Use toast
  const toast = useToast()
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null)

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null
  onMounted(() => {
    calendarApi = refCalendar.value.getApi()
  })

  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  const calendarsColor = {
    Business: 'primary',
    Holiday: 'success',
    Personal: 'danger',
    Family: 'warning',
    ETC: 'info',
  }

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  const blankEvent = {
    title:'',
    start :'',
    end :'',
    allDay :'',
    extendedProps: { _id :'', site :'', locations :[], supervisor:'', training:'', shift:'', description:'', type:'', kpi_contract:'',minutes:'' },
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  // *===========================================================================---*
  // *--------- Calendar API Function/Utils --------------------------------------------*
  // Template Future Update: We might move this utils function in its own file
  // *===========================================================================---*

  // ------------------------------------------------
  // (UI) addEventInCalendar
  // ? This is useless because this just add event in calendar and not in our data
  // * If we try to call it on new event then callback & try to toggle from calendar we get two events => One from UI and one from data
  // ------------------------------------------------
  // const addEventInCalendar = eventData => {
  //   toast({
  //     component: ToastificationContent,
  //     position: 'bottom-right',
  //     props: {
  //       title: 'Event Added',
  //       icon: 'CheckIcon',
  //       variant: 'success',
  //     },
  //   })
  //   calendarApi.addEvent(eventData)
  // }

  // ------------------------------------------------
  // (UI) updateEventInCalendar
  // ------------------------------------------------
  const updateEventInCalendar = (updatedEventData, propsToUpdate, extendedPropsToUpdate) => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Updated',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })

    const existingEvent = calendarApi.getEventById(updatedEventData.id)

    // --- Set event properties except date related ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setProp
    // dateRelatedProps => ['start', 'end', 'allDay']
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index]
      existingEvent.setProp(propName, updatedEventData[propName])
    }

    // --- Set date related props ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setDates
    existingEvent.setDates(updatedEventData.start, updatedEventData.end, { allDay: updatedEventData.allDay })

    // --- Set event's extendedProps ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < extendedPropsToUpdate.length; index++) {
      const propName = extendedPropsToUpdate[index]
      existingEvent.setExtendedProp(propName, updatedEventData.extendedProps[propName])
    }
  }

  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = eventId => {
    toast({
      component: ToastificationContent,
      position: 'bottom-right',
      props: {
        title: 'Event Removed',
        icon: 'TrashIcon',
        variant: 'danger',
      },
    })
    calendarApi.getEventById(eventId).remove()
  }

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = eventApi => {
    
    // const {
      
    //   title,
    //   start,
    //   end,
    //   allDay,
    //   extendedProps: { _id, site, locations, supervisor, training, shift, description },
    //   // eslint-disable-next-line object-curly-newline
    // } = eventApi
    
    return {
      
    title:eventApi.title,
    start : moment(eventApi.start).format('YYYY-MM-DD'),
    end :moment(eventApi.end).subtract(1,'days').format('YYYY-MM-DD'),
    allDay :eventApi.allDay,
    extendedProps: { _id :eventApi.extendedProps._id, site :eventApi.extendedProps.site, locations :eventApi.extendedProps.locations, 
      supervisor:eventApi.extendedProps.supervisor, training:eventApi.extendedProps.training, shift:eventApi.extendedProps.shift, 
      description:eventApi.extendedProps.description, type: eventApi.extendedProps.type, kpi_contract:eventApi.extendedProps.siteData[0].kpi_contract,minutes:eventApi.extendedProps.minutes},
  
      /*title: eventApi.title ,
      start:'',
      end:'',
      allDay:true,
      extendedProps: {
        _id, site, locations, supervisor, training, shift, description
      },*/
    }
  }

  // ------------------------------------------------
  // addEvent
  // ------------------------------------------------
  const addEvent = eventData => {
    store.dispatch('calendar/addEvent', { event: eventData }).then(() => {
      // eslint-disable-next-line no-use-before-define
      refetchEvents()
    })
  }

  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const updateEvent = eventData => {
    
    isEventHandlerSidebarActive.value = false;
    Bus.$emit('refreshCalender');
  }

  // ------------------------------------------------
  // removeEvent
  // ------------------------------------------------
  const removeEvent = () => {
    const eventId = event.value.extendedProps._id
    
    var msg = 'Are you sure want to delete this Task?';
    var message='Task Deleted Successfully.';

    Swal.fire({
              title: 'Please Confirm',
              text: msg,
              icon: 'warning',
              position: 'top-center',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes',
              cancelButtonText: 'No'
            })
          .then(result => {
            if (result.value) {
                return store.dispatch(POST_API, {
                  data:{
                        id: eventId,
                        status:'deleted'
                      },
                  api:"/api/change-task-status",
                    })
                    .then(() => {
                        if (store.getters.containsErrors) {
                            //this.error_message = store.getters.getErrors;
                            //this.showAlert();
                        } else {
                          
                          Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: message,
                            showConfirmButton: false,
                            timer: 1500
                          })
                          
                          isEventHandlerSidebarActive.value = false;
                          Bus.$emit('refreshCalender');
                        }
                    });
            }
        })
        .catch(err => {
            
        })
  }

  // ------------------------------------------------
  // refetchEvents
  // ------------------------------------------------
  const refetchEvents = () => {
    calendarApi.refetchEvents()
  }

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed(() => store.state.calendar.selectedCalendars)

  watch(selectedCalendars, () => {
    refetchEvents()
  })

  // --------------------------------------------------------------------------------------------------
  // AXIOS: fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // --------------------------------------------------------------------------------------------------
  
  const fetchEvents = (info, successCallback) => {
    // If there's no info => Don't make useless API call
    if (!info) return

    // Fetch Events from API endpoint
    /*store
      .dispatch('calendar/fetchEvents', {
        calendars: selectedCalendars.value,
      })
      .then(response => {
        console.log(response.data);
        successCallback(response.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching calendar events',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })*/

      // return store.dispatch(POST_API, {
      //      data:{
      //        /*site:this.siteData ? this.siteData._id : null,

      //        frequency:this.frequencyData ? this.frequencyData.name :null,*/
      //      },
      //      api: '/api/all-calendar-tasks'
      //   })
      //   .then(() => {
      //       if (store.getters.containsErrors) {
      //           this.error_message = this.$store.getters.getErrors;
      //           this.showDismissibleAlert = true;
      //           window.scrollTo(0,0);
      //       } else {
      //           //this.showDismissibleAlert = false;
      //           //var data  = store.getters.getResults.data;
                
      //           //this.calendarOptions.events = data;
      //           //successCallback(data)

                
      //       }
      //   });
  }

  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: 'dayGridMonth',
    headerToolbar: {
      start: 'sidebarToggle, title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
    },
    events: fetchEvents,
    eventOrder:'order',
    //eventOrderStrict:true,
    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: false,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: false,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: false,
    droppable :false,
    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 4,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      //const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]

      // return [
      //   // Background Color
      //   `bg-light-${calendarEvent.backgroundColor}`,
      // ]
    },
    eventClick({ event: clickedEvent }) {
      
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      
      /*if(clickedEvent._def.title != '' && typeof clickedEvent._def.extendedProps._id != 'undefined'){
        
        event.value = grabEventDataFromEventApi(clickedEvent)
        isEventHandlerSidebarActive.value = true
        console.log(event.value);
      
      }*/
      console.log(clickedEvent);

      if (typeof clickedEvent._def.extendedProps.periodic != 'undefined' && clickedEvent._def.extendedProps.periodic == true) {
        console.log('a');

        /*var filter = {
            keyword:'null',
            sortBy: 'null',
            sortDirection: 'true',
            site:clickedEvent._def.extendedProps.site,
            frequency:clickedEvent._def.extendedProps.type,
            page:'1',
            // updated new params
            start : moment(clickedEvent.start).tz('Asia/Singapore').format('YYYY-MM-DD'),
            end : moment(clickedEvent.start).tz('Asia/Singapore').format('YYYY-MM-DD'),
            status : 'null',
            group : 'null',
            assignedStatus : 'null',
            crew : 'null',
        };*/


        var filter = {
            site:clickedEvent._def.extendedProps.site,
            location:'null',
            group : 'null',
            frequencyData:clickedEvent._def.extendedProps.type,
            crew : 'null',
            start : moment(clickedEvent.start).tz('Asia/Singapore').format('YYYY-MM-DD'),
            end : moment(clickedEvent.start).tz('Asia/Singapore').format('YYYY-MM-DD'),
            tab_status : 'all',
            
        };

        /*var url = '/client/periodic-sub-tasks/'+filter.keyword+'/'+filter.sortBy+'/'+filter.sortDirection+'/'+filter.site
        +'/'+filter.frequency+'/'+filter.page+'/'+filter.start+'/'+filter.end+'/'+filter.status+'/'+filter.group
        +'/'+filter.assignedStatus+'/'+filter.crew;*/

        var url = '/client/periodic-sub-tasks/'+filter.site+'/'+filter.location+'/'+filter.group+'/'+filter.frequencyData
        +'/'+filter.crew+'/'+filter.start+'/'+filter.end+'/'+filter.tab_status;


        url = process.env.VUE_APP_URL+url;
        window.open(url,'_blank');
        //let route = this.$router.params({ name: 'periodic-sub-tasks', params: filter });
        //window.open(route.href,'_blank');


      }else if(typeof clickedEvent._def.extendedProps.periodic != 'undefined' && clickedEvent._def.extendedProps.periodic == false) {
        console.log('b');

        /*var filter = {
            keyword:'null',
            sortBy: 'null',
            sortDirection: 'true',
            site:clickedEvent._def.extendedProps.site,
            type:clickedEvent._def.extendedProps.type,
            month:moment(clickedEvent.start).tz('Asia/Singapore').format('MMMM'),
            year:moment(clickedEvent.start).tz('Asia/Singapore').format('YYYY'),
            page:'1',
        };*/

        var filter = {
            site:clickedEvent._def.extendedProps.site,
            location:'null',
            type:clickedEvent._def.extendedProps.type,
            crew : 'null',
            start : moment(clickedEvent.start).tz('Asia/Singapore').format('YYYY-MM-DD'),
            end : moment(clickedEvent.start).tz('Asia/Singapore').format('YYYY-MM-DD'),
            tab_status : 'all',
            
        };
        
        /*var url = '/client/adhoc-tasks/'+filter.keyword+'/'+filter.sortBy+'/'+filter.sortDirection+'/'+filter.site
        +'/'+filter.type+'/'+filter.month+'/'+filter.year+'/'+filter.page;
        url = process.env.VUE_APP_URL+url;*/

        var url = '/client/adhoc-tasks/'+filter.site+'/'+filter.location+'/'+filter.type+'/'+filter.crew+'/'+filter.start+'/'+filter.end+'/'+filter.tab_status;
        url = process.env.VUE_APP_URL+url;

        window.open(url,'_blank');


      }else{
        console.log('c');
      }



      //window.open('http://facebook.com','_blank');
        
      // eslint-disable-next-line no-use-before-define
      
    },

    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
    },

    dateClick(info) {
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */
      event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { start: info.date })))
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      //updateEvent(grabEventDataFromEventApi(droppedEvent))
      if (typeof droppedEvent._def.extendedProps._id != 'undefined') {
        var id = droppedEvent._def.extendedProps._id;
        var start = droppedEvent._instance.range.start;
        var end = droppedEvent._instance.range.end;

        return store.dispatch(POST_API, {
             data:{
               id:id,
               start:start,
               end:end
             },
             api: '/api/drop-calender'
          })
          .then(() => {
              if (store.getters.containsErrors) {
                  /*this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);*/
                  Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: store.getters.getErrors,
                    showConfirmButton: false,
                    timer: 1500
                  })
              } else {
                  //this.showDismissibleAlert = false;
                  //var data  = store.getters.getResults.data;
                  
                  //this.calendarOptions.events = data;
                  Bus.$emit('refreshCalender');
                  
              }
          });
        }else{
          Bus.$emit('refreshCalender');
        }
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      //updateEvent(grabEventDataFromEventApi(resizedEvent))
      if (typeof resizedEvent._def.extendedProps._id != 'undefined') {
        
        var id = resizedEvent._def.extendedProps._id;
        var start = resizedEvent._instance.range.start;
        var end = resizedEvent._instance.range.end;

        return store.dispatch(POST_API, {
             data:{
               id:id,
               start:start,
               end:end
             },
             api: '/api/drop-calender'
          })
          .then(() => {
              if (store.getters.containsErrors) {
                  /*this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);*/
                  Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: store.getters.getErrors,
                    showConfirmButton: false,
                    timer: 1500
                  })
              } else {
                  //this.showDismissibleAlert = false;
                  //var data  = store.getters.getResults.data;
                  
                  //this.calendarOptions.events = data;
                  Bus.$emit('refreshCalender');
                  
              }
          });
      }else{
        Bus.$emit('refreshCalender');
      }
    },
    datesSet(payload){
      
      var data = {
        start : payload.startStr,
        end : payload.endStr,
      }

      Bus.$emit('setDates',data);
    },
    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,
  })

  // ------------------------------------------------------------------------

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const isEventHandlerSidebarActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)

  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    event,
    clearEventData,
    addEvent,
    updateEvent,
    removeEvent,
    refetchEvents,
    fetchEvents,

    // ----- UI ----- //
    isEventHandlerSidebarActive,
  }
}
