<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive && eventLocal.allDay == true"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >

      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Update Task
          </h5>
          <div>
            <feather-icon
              
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-event'); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        
          <!-- Form -->
          
          <b-form
            class="p-2"
            @submit.prevent="formSubmit"
            
          >     
                

                
                <b-form-group
                  :label="eventLocal.extendedProps.type == 'Reactive' || eventLocal.extendedProps.type == 'Ad-Hoc' || eventLocal.extendedProps.type == 'Urgent' ? 'Request Detail' : 'Task Name'"
                  class="required"
                >
                  <b-form-input
                    placeholder="2 to 100 characters"
                    v-model="eventLocal.title"
                  />
                </b-form-group>

                <b-form-group
                  label="Project Site"
                  class="required"

                >
                  <b-form-select v-model="eventLocal.extendedProps.site" @change="resetData()">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name}}</b-form-select-option>
                  </b-form-select>
                    
                </b-form-group>

                <b-form-group
                  label="Location"
                  class="required"

                >
                  <v-select
                    v-model="eventLocal.extendedProps.locations"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    label="name"
                    :options="locations"
                    placeholder="Select"
                    :close-on-select=false
                            :clear-on-select=false
                  />
                    
                </b-form-group>

                <b-form-group
                  label="Request Type"
                  class="required"
                  v-if="eventLocal.extendedProps.type == 'Reactive' || eventLocal.extendedProps.type == 'Ad-Hoc' || eventLocal.extendedProps.type == 'Urgent'"
                >

                <div class="demo-inline-spacing mb-2" >
                  <b-form-radio
                    v-model="eventLocal.extendedProps.type"
                    name="some-radio9"
                    value="Ad-Hoc"
                    class="custom-control-warning"
                    @change="eventLocal.extendedProps.minutes = ''"
                  >
                    Ad-Hoc
                  </b-form-radio>
                  <b-form-radio
                    v-model="eventLocal.extendedProps.type"
                    name="some-radio9"
                    value="Reactive"
                    class="custom-control-warning"
                    @change="eventLocal.extendedProps.minutes = ''"
                  >
                    Reactive
                  </b-form-radio>

                  <b-form-radio
                    v-model="eventLocal.extendedProps.type"
                    name="some-radio9"
                    value="Urgent"
                    class="custom-control-warning"
                    v-if="eventLocal.extendedProps.kpi_contract == 'yes'"
                    @change="eventLocal.extendedProps.minutes = ''"
                  >
                    Urgent
                  </b-form-radio>

                </div>
                </b-form-group>

                <b-form-group
                  label="Number of minutes"
                  class="required"
                  v-if="eventLocal.extendedProps.type == 'Urgent'"
                >
                  <b-form-select v-model="eventLocal.extendedProps.minutes">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="minute" v-for="minute in minutes" :key="minute">{{minute}}</b-form-select-option>
                  </b-form-select>

                </b-form-group>


                <!-- <b-form-group
                  label="Supervisor"
                  class="required"

                >
                  <b-form-select v-model="eventLocal.extendedProps.supervisor">

                    <b-form-select-option value="null">Select</b-form-select-option>
                    <b-form-select-option :value="supervisor._id" v-for="supervisor in supervisors" :key="supervisor._id">{{supervisor.full_name}}</b-form-select-option>
                  </b-form-select>
                    
                </b-form-group> -->

                <b-form-group
                  label="Training Content"
                  class=""

                >
                  <b-form-select v-model="eventLocal.extendedProps.training">

                    <b-form-select-option value="null">Select</b-form-select-option>
                    <b-form-select-option :value="training._id" v-for="training in trainings" :key="training._id">{{training.name}}</b-form-select-option>

                  </b-form-select>
                    
                </b-form-group>

                <b-form-group
                  label="Start Date"
                  class="required"

                > 
                  <!-- <datepicker v-model="eventLocal.start" :use-utc="clearButton" :clear-button="clearButton" :format="customFormatter"></datepicker> -->
                  <b-form-datepicker
                  
                    v-model="eventLocal.start"
                    
                    :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                    locale="en"
                  />
                    
                </b-form-group>

                <b-form-group
                  label="End Date"
                  class="required"

                >
                  <!-- <datepicker v-model="eventLocal.end" :use-utc="clearButton" :clear-button= "clearButton" :format="customFormatter"></datepicker> -->
                  <b-form-datepicker
                  
                    v-model="eventLocal.end"
                    
                    :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                    locale="en"
                  />

                    
                </b-form-group>

                <b-form-group
                  label="Shift"
                  class="required"
                  v-if="eventLocal.extendedProps.type != 'Reactive' && eventLocal.extendedProps.type != 'Ad-Hoc' && eventLocal.extendedProps.type != 'Urgent'"

                >
                  <b-form-select v-model="eventLocal.extendedProps.shift">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="shift" v-for="shift in shift_array" :key="shift">{{shift}}</b-form-select-option>
                    
                  </b-form-select>
                    
                </b-form-group>

                <b-form-group
                  label="Description"
                  class="required"
                  v-if="eventLocal.extendedProps.type != 'Reactive' && eventLocal.extendedProps.type != 'Ad-Hoc' && eventLocal.extendedProps.type != 'Urgent'"
                >
                  <b-form-textarea
                    placeholder="upto 500 characters"
                    rows="3"
                    v-model="eventLocal.extendedProps.description"
                  />
                </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="mr-2"
                type="submit"
              >
                {{ 'Update'}}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormTextarea,BSidebar,BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'

import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import useCalendarEventHandler from './useCalendarEventHandler'
import { GET_API, POST_API } from "../../../../store/actions.type";
import Datepicker from 'vuejs-datepicker';
import moment from "moment";

export default {
  components: {
    Datepicker,
    BCard, 
    BRow, 
    BCol, 
    BFormGroup, 
    BFormInput, 
    BFormCheckbox, 
    BForm, 
    BButton,
    BAlert,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal, 
    BFormRadio,
    BFormTimepicker,
    BFormTextarea,
    vSelect,
    flatPickr,
    BSidebar,
    BFormDatepicker
    
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  
  watch: { 
    event: function(newVal, oldVal) { // watch it
      this.updateLocations(newVal);
    }
  },
  data() {
    return {
      minutes:[],
      sites:[],
      locations:[],
      shift_array:[],
      supervisors:[],
      trainings:[],
      clearButton:true,
    }
  },
  methods: {
    customFormatter(date) {
      return moment(date).format('DD MMM YYYY');
    },
    updateLocations(newVal){
      this.eventLocal.extendedProps.site = newVal.extendedProps.site;
      this.allLocations();
      this.allSupervisor();
      this.siteDetail();
    },
    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
             
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                //this.showDismissibleAlert = true;
                //window.scrollTo(0,0);
                this.swalAlert();
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;
                return this.sites;
            }
        });
    },
    trainingData(){
      return this.$store.dispatch(GET_API, {
           data:{
             
           },
           api: '/api/all-trainings'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                //this.showDismissibleAlert = true;
                //window.scrollTo(0,0);
                this.swalAlert();
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.trainings = data;
                return this.trainings;
            }
        });
    },
    resetData(){
      this.eventLocal.extendedProps.locations = [];
      this.eventLocal.extendedProps.supervisor = null;
      this.eventLocal.extendedProps.minutes = '';
      this.allLocations();
      this.allSupervisor();
      this.eventLocal.extendedProps.shift = '';

      if(this.eventLocal.extendedProps.site != '') {

        this.siteDetail();
      }else{
        this.eventLocal.extendedProps.kpi_contract = 'no';
      }

    },
    allLocations(){
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.eventLocal.extendedProps.site
           },
           api: '/api/all-locations'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                //this.showDismissibleAlert = true;
                //window.scrollTo(0,0);
                this.swalAlert();
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.locations = data;
                return this.locations;
            }
        });
    },
    allSupervisor(){
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.eventLocal.extendedProps.site
           },
           api: '/api/all-supervisors'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                //this.showDismissibleAlert = true;
                //window.scrollTo(0,0);
                this.swalAlert();
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.supervisors = data;
                return this.supervisors;
            }
        });
    },

    siteDetail(){
      return this.$store.dispatch(POST_API, {
           data:{
             id:this.eventLocal.extendedProps.site
           },
           api: '/api/site-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                if (data.kpi_contract == 'yes') {
                  
                  this.eventLocal.extendedProps.kpi_contract = 'yes';
                  this.minutes = [];
                  
                  var n = [];
                  
                  data.minutes.forEach(item => {
                    n.push(item.value);
                  })

                  this.minutes = n;
                }else{
                  this.eventLocal.extendedProps.kpi_contract = 'no';
                  this.minutes = [];
                }

                var shift_array = [];

                data.shifts.forEach(item => {
                  shift_array.push(item.shift);
                })

                this.shift_array = shift_array;
                
            }
        });
    },
    swalAlert(){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500
      })
    }
    ,
    formSubmit(){
      //console.log(this.eventLocal);

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.eventLocal
           },
           api: '/api/calender-update'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.swalAlert();
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;

                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Task Updated Successfully',
                  showConfirmButton: false,
                  timer: 1500
                })

                this.$emit('update-event');
                
            }
        });
      
    }
  },


  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      
      //resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,

      // Form Validation
      //resetForm,
      
    }
  },
  mounted(){
    this.allSites();
    this.trainingData();

    /*this.allLocations();
    this.allSupervisor();*/
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
