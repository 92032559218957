<template>
  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>
    <b-card no-body>
    
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
            <!-- <label>Status</label> -->
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sites"
              label="site_name"
              class="w-100"
              placeholder="Project Site"
              @input="fetchEvent"
              v-model="siteData"
              :clearable="false"
              >
                <template #selected-option="{ site_name }">
                  <span v-if="site_name.length < 23">{{site_name}}</span>
                  <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '..'}}</span>
                </template>
              </v-select>
          </b-col>

          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
            <!-- <label>Status</label> -->
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="frequencies"
              label="name"
              class="w-100"
              placeholder="Frequency"
              @input="fetchEvent"
              v-model="frequencyData"
              :clearable="true"
            />
          </b-col>

          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
            
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="months"              
              class="w-100"
              placeholder="Month"
              @input="goToDate"
              v-model="month"
              :clearable="false"
            />
          </b-col>

          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mobile_tab_max_width_flex">
            
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="yearData"
              class="w-100"
              placeholder="Year"
              @input="goToDate"
              v-model="year"
              :clearable="false"
            />
          </b-col>
          
        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body>
    
      <b-card-body>
        <b-row>
          <b-col md="12">
            <p style="margin-bottom:0px;">Legends : </p>
            <div class="demo-inline-spacing" v-if="legends && legends.length > 0">
              <div v-for="(legend,index) in legends" :key="index">
                <b-avatar
                  rounded="sm"
                  variant="light-primary"
                  :style="{'background':legend.backgroundColor,'color':legend.textColor,'border':3+'px solid '+legend.borderColor,'width':'1.5rem','height':'1.5rem'}"
                  text="" 
                  class="mr-1"
                />
                <span v-if="typeof legend.periodic != 'undefined'">{{legend.type}}</span>
                <span v-else-if="legend.type == 'Total'">{{'Total Task'}}</span>
                <span v-else>{{'Public Holiday'}}</span>

              </div>

            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <div class="app-calendar overflow-hidden border">
      <div class="row no-gutters">
        <!-- Sidebar -->
        <!-- <div
          class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
          :class="{'show': isCalendarOverlaySidebarActive}"
        >
          <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" />
        </div> -->

        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
              >
                <template #eventContent="arg">
                  <div style="text-align:center;" v-b-popover.hover.top="popOverContent(arg)">
                      
                    <span style="white-space: normal;"> {{arg | eventCal}}</span>
                  </div>
                </template>
            </full-calendar>
            </div>
          </div>
        </div>

        <!-- Sidebar Overlay -->
        <!-- <div
          class="body-content-overlay"
          :class="{'show': isCalendarOverlaySidebarActive}"
          @click="isCalendarOverlaySidebarActive = false"
        /> -->
        <!-- <calendar-event-handler
          v-model="isEventHandlerSidebarActive"
          :event="event"
          :clear-event-data="clearEventData"
          @remove-event="removeEvent"
          @add-event="addEvent"
          @update-event="updateEvent"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import calendarStoreModule from './calendarStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'
import { GET_API, POST_API } from "../../../store/actions.type";
import Bus from "../../../event-bus";
import vSelect from 'vue-select';
import moment from "moment-timezone";

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BCardBody,VBPopover,BBreadcrumb,
} from 'bootstrap-vue'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
    BRow,
    BCol,
    BCard,
    BCardBody,
    vSelect,
    BBreadcrumb,
    BBadge,
    BAvatar
  },
  directives: {
    'b-popover': VBPopover,
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar()

    //fetchEvents()

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,
      

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    }
  },
  data(){
    return {
      siteData:null,
      frequencyData:null,

      frequencies:[],
      sites:[],
      /*fromYear: moment().format('YYYY'),
      toYear:moment().format('YYYY'),*/
      start : null/*moment.utc().startOf('month').subtract(7, 'days').format('YYYY-MM-DD')*/,
      end : null/*moment.utc().endOf('month').add(10, 'days').format('YYYY-MM-DD')*/,
      yearData:[],
      month:moment().format('MMMM'),
      year:moment().format('YYYY'),
      months:[
         'January', 
         'February', 
         'March', 
         'April', 
         'May', 
         'June', 
         'July ', 
         'August', 
         'September', 
         'October', 
         'November', 
         'December'
      ],

      legends:[]
    }
  },
  methods :{
    popOverContent(arg){

      var location = [];
      
      if (typeof arg.event._def.extendedProps.type != 'undefined') {
        var className = '';

        if (arg.event._def.extendedProps.type == 'Ad-Hoc') {
          className = 'adhoc';
        }else if(arg.event._def.extendedProps.type == 'Reactive'){
          className = 'reactive';
        }else if(arg.event._def.extendedProps.type == 'Urgent'){
          className = 'urgent';
        }else if(arg.event._def.extendedProps.type == 'Daily'){
          className = 'daily';
        }else if(arg.event._def.extendedProps.type == 'Weekly'){
          className = 'weekly';
        }else if(arg.event._def.extendedProps.type == 'Fortnightly'){
          className = 'fortnightly';
        }else if(arg.event._def.extendedProps.type == 'Monthly'){
          className = 'monthly';
        }else if(arg.event._def.extendedProps.type == 'Quarterly'){
          className = 'quaterly';
        }else if(arg.event._def.extendedProps.type == 'Half-Yearly'){
          className = 'halfyearly';
        }else if(arg.event._def.extendedProps.type == 'Yearly'){
          className = 'yearly';
        }else if(arg.event._def.extendedProps.type == 'Once in two months'){
          className = 'onceinmonth';
        }else if(arg.event._def.extendedProps.type == 'Custom'){
          className = 'custom';
        }else if(arg.event._def.extendedProps.type == 'Total'){
          className = 'total';
        }


        return {title:arg.event._def.extendedProps.type +' Task', customClass:className};
      }else{
        console.log('Public Holiday');
      }
      

       //return {title:'' +' Task',content: '', html: true, variant:'primary'};
    },
    fetchEvent(){

      return this.$store.dispatch(POST_API, {
           data:{
             site:this.siteData ? this.siteData._id : null,
             frequency:this.frequencyData ? this.frequencyData.name :null,
             start:this.start,
             end:this.end,
             om_sites:this.$store.getters.currentUser.om_sites,
             role:this.$store.getters.currentUser.role
             /*fromYear:this.fromYear,
             toYear:this.toYear,*/

           },
           api: '/api/all-calendar-tasks'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                this.calendarOptions.events = data;

                this.legends = [
                    ...new Map(data.map((item) => [item["type"], item])).values(),
                ];

                console.log(this.legends);

                return data;
            }
        });
    },
    allSites(){
      return this.$store.dispatch(POST_API, {
         data:{
           role:this.$store.getters.currentUser.role,
           om_sites:this.$store.getters.currentUser.om_sites,
         },
         api: '/api/all-sites'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.sites = this.$store.getters.getResults.data;
              /*this.siteData = this.sites.length > 0 ? this.sites[0] : null;*/

              // if (this.sites.length == 1 ) {
              //    this.siteData = this.sites.length > 0 ? this.sites[0] : null;
              //    this.fetchEvent();
              //}
              /*var obj = {
                _id:'all-site',
                site_name:'All Project Sites',
                pdf_date_format: 'DD MMM YYYY',
                pdf_time_format: 'HH:mm'
              }
              this.sites.unshift(obj);*/
              //this.fetchEvent();

              this.siteData = this.defaultSite(this.sites);
              this.fetchEvent();

              return this.sites;
          }
      });
    },

    allFrequencies(){
      return this.$store.dispatch(POST_API, {
           data:{
              page:'calender'
           },
           api: '/api/all-periodic-frequencies'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.frequencies = data;
                this.frequencies.push({name:'Ad-Hoc'})
                this.frequencies.push({name:'Reactive'})
                this.frequencies.push({name:'Urgent'})
                //this.frequencies.push({name:'Public Holiday'})
                /*this.frequencyData = this.frequencies.length > 0 ? this.frequencies[0]: null;*/

                return this.frequencies;
            }
        });
    },
    calYear(){
      var year = moment().format('YYYY');
      var yearData = [];
      var start = parseInt(year) - 2;
      var end =  parseInt(year) + 5;

      for (var i = start ; i <= end; i++) {
        yearData.push(i);
      }

      this.yearData = yearData;
    },
    goToDate(){
      
      var date = null;

      if (this.month == null && this.year == null) {
        date = moment.utc().startOf('month').format('YYYY-MM-DD');
      }else if(this.month == null && this.year != null){

        date = moment.utc().startOf('month').format('MM-DD');
        var year = this.year;
        date =  year.toString() + '-'+ date;
        date = moment.utc(date).startOf('month').format('YYYY-MM-DD');

      }else if(this.month != null && this.year == null){

        var year = moment.utc().startOf('month').format('YYYY');
        date = year +'-' + moment().month(this.month).startOf('month').format('MM-DD');
        date = moment.utc(date).startOf('month').format('YYYY-MM-DD');

      }else{

        var year = this.year.toString();

        date = year +'-' + moment().month(this.month).startOf('month').format('MM-DD');
        date = moment.utc(date).startOf('month').format('YYYY-MM-DD');

      }
      
      var t = this.$refs.refCalendar.getApi();
      t.gotoDate( date );
      
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Calendar',
      },{
        to:null,
        text: 'Tasks',
        active:true
      }];
      return item;
    }
  },
  mounted(){

    Bus.$on('refreshCalender', () => {
      console.log('kahi se event aya');
      this.fetchEvent();
    })

    Bus.$on('setDates', (data) => {
      console.log('set date hui hai');

      if (moment.utc(data.start).format('YYYY-MM-DD') != this.start) {
          console.log('hogya hit');
          this.start = moment.utc(data.start).format('YYYY-MM-DD');
          this.end = moment.utc(data.end).format('YYYY-MM-DD');
          this.fetchEvent();
      }
    })

    this.allSites();
    this.allFrequencies();
    //this.fetchEvent();

    
    /*var t = this.$refs.refCalendar.getApi();
    t.gotoDate( '2022-08-22T12:30:00-05:00' );*/
    this.calYear();
    this.goToDate();
    //this.loadFirstDate()
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

.b-icon.bi {
    display: none !important;
}

</style>
